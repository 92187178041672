<template>
    <div class="seletrr">
        <treeselect
        :options="treeList"
        :value="value"
        :normalizer="normalizer"
        @select="selectDepart"
        />
    </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import methodlist from '@/utils/methods'
import { mapState } from 'vuex'
export default {
  computed:{
    ...mapState({
      current: state=>state.selectree.selectree,
    })
    
  },
    components:{
        Treeselect
    },
    props:{
      treeList:Array
    },
  data(){
    return{
      UserInfo:'',
      value: null,
      options:[
          {
            id: 1,
            name: '深圳企业平台',
            prant_id: 0,
            enterprise_id: '0',
            children: [
              {
                id: 9,
                name: '五金一场',
                enterprise_id: '2',
                prant_id: 1,
                children: [
                  {
                    id: 10,
                    name: '国内市场',
                    prant_id: 9,
                    enterprise_id: '2',
                    children: [
                      {
                        id: 17,
                        name: '领导部',
                        prant_id: 10,
                        enterprise_id: '2',
                        children: [
                          {
                            id: 18,
                            name: '国外市场',
                            prant_id: 17,
                            enterprise_id: '2'
                          }
                        ]
                      },
                      {
                        id: 11,
                        name: '生产部',
                        prant_id: 10,
                        enterprise_id: '2'
                      }
                    ]
                  }
                ]
              }
            ]
          }
      ],
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          children: node.children,
        }
      },
    }
  },
  
  mounted(){
    this.getDepartmentlist()
   this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
       console.log(`4555`,this.$store.state.selectree.selectree)
    // console.log(this.UserInfo.agent_id)
  },
  methods:{
    getDepartmentlist(){
      let data={
        method:'co.dept.list',
        agent_id:this.UserInfo.agent_id,
        manager_id:this.UserInfo.manager_id,
      }
      this.$serve(data).then(res=>{
        console.log(res)
      })
    },

    selectDepart(val){
      this.$emit('selectDepart',val)
    }
  }
}
</script>
<style lang="less" scoped>
.seletrr .el-form-item /deep/ .el-form-item__content{
    line-height: 24px;
}
.el-form-item__content{
    line-height: 24px;
}
</style>