<template>
    <div class="statusquery"> 
        <div style="display: flex;">
            <Headbuttom></Headbuttom>
            <div class="dei_rowp">
              <el-button type="primary" size="small" class="but" @click="handRegister">
                <i class="el-icon-plus"></i>
                {{$t('devtable.djj')}}
              </el-button>
              <div @click="handFromshow"><el-input
                size="small"
                class="dev_dowm"
                :placeholder="$t('devtable.qdjcx')"
                suffix-icon="el-icon-arrow-down"
                :readonly="true"
                >
              </el-input></div>
            </div>
        </div>

        <div class="searchfrom" v-show="searflag">
          <Searchfrom @searchListWithParams='searchListWithParams'></Searchfrom>
        </div>
        <Registration @regsiterSuccess="regsiterSuccess" ref="registration"></Registration>
        <Tables @handles="handles" @handlpages="handlpages" @handlerow="handlerow" @handleSizeChange="handleSizeChange" :tableLabel="tableLabel" :tableData="tableData" :flags="flags" :configs="configs" :operation="operation">
        </Tables>
        <!-- <Dialog @handlpages="handlpages" :dialog="dialog" @handleClose="handleClose" @dialogVisibletrue="dialogVisibletrue">
          <div class="dialogimg">
             <el-image :src="preview_imgurl"></el-image>
          </div>
        </Dialog> -->
        <Dialog ref="dialog" :dialog="dialog" @handleClose="handleClose" @dialogVisibletrue="dialogVisibletrue">
          <div style="display:flex; justify-content: center;max-height:250px">
            <el-image :src="preview_imgurl"></el-image>
          </div>
        </Dialog>        
        <Userdialog @userhandleClose="userhandleClose" :userdialog="userdialog" @userdialogVisibletrue="userdialogVisibletrue">
            <el-form :model="delform">
                <el-form-item :label="$t('devtable.yhm')" :label-width="formLabelWidth">
                <el-input v-model="delform.user_name_input" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('devtable.mm')" :label-width="formLabelWidth">
                <el-input v-model="delform.user_pass_input" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
        </Userdialog>
    </div>
</template>
<script>
import Tables from '@/components/table'
import Dialog from '@/components/dialog/img.vue'
import Userdialog from '@/components/dialog/userfrom'
import Searchfrom from './components/searchfrom'
import Registration from './components/registration'

import methodlist from '../../utils/methods'
import {formatDate} from '../../utils/date'

export default {
    components:{
        Tables,
        Dialog,
        Userdialog,
        Searchfrom,
        Registration
    },
    data(){
        return{
            formLabelWidth:'90px',
            form: {
                deptNameInput: ''
            },
            dialog:{
              dialogVisible:false,
              title:this.$t('devtable.yllfryzp')
            },
            userdialog:{
              dialogVisible:false,
              title:this.$t('devtable.tsxx')
            },
            visible: true,
            
            tableData: [
            ],
            flags: {
                show: false, // 多选是否显示
                ishow: false // 序号是否显示
            },
            // 分页数据
            configs: {
                page_index:1,
                pagesize: 10,
                total: 200,
                loading: false // 加载状态
            },
            
            searchParams: {
              name: '',
              mobile: '',
              person_name: '',
              person_mobile: '',
              start_time: '0',
              end_time: '0',
              reg_face_status:'-1'
            },
            delform: {
              user_name_input: '',
              user_pass_input: ''
            },
            searflag:false,
            preview_imgurl: 'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3363295869,2467511306&fm=26&gp=0.jpg',
            UserInfo:''
        }
    },
    computed:{
      operation: function(){
               return{
                  width:'120px',
                  data:[
                    {
                      name: this.$t('tablename.sc'),
                      type: 'danger'
                    },
                  ]
               }
            },
      tableLabel:function(){
        return[
                 {
                    prop: 'id',
                    label: this.$t('devtable.zcid'),
                    align: 'center',
					width:125
                 },
                {
                    prop: 'name',
                    label: this.$t('devtable.lfrxm'),
                    align: 'center',
					width:110
                },
                {
                    prop: 'mobile',
                    label: this.$t('devtable.sjhm'),
                    align: 'center'
                },
                {
                    prop: 'gender_hint',
                    label: this.$t('devtable.xb'),
                    align: 'center'
                },
                {
                    prop: 'dept_name',
                    label: this.$t('devtable.bfrbm'),
                    align: 'center',
					width:140
                },
                {
                    prop: 'person_name',
                    label: this.$t('devtable.bfr'),
                    align: 'center',
					width:155
                },
                {
                    prop: 'person_mobile',
                    label: this.$t('devtable.sfrsjhm'),
                    align: 'center',
					width:155
                },
                {
                    prop: 'face_url_hint',
                    label: this.$t('devtable.lfrzp'),
                    align: 'center',
					width:125
                },
                {
                    prop: 'visit_time_hint',
                    label: this.$t('devtable.lfsj'),
                    align: 'center',
                    width:'160'
                },
                {
                    prop: 'car_number',
                    label: this.$t('devtable.lfcph'),
                    align: 'center',
					width:130
                },
                
            ]
        } ,
    },
    mounted () {
      this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
      this.getApproveVisitorList(1);
    },
    methods: {
      handRegister(){
        this.$refs.registration.centerDialogVisible=true
        this.$refs.registration.startRegisterVisitor();
      },
      
      handFromshow(){
        this.searflag = !this.searflag
      },
      onSubmit() {
        console.log('submit!');
      },
      //预览人脸图片
      handles(v){
         console.log("handles",v);
        this.$refs.dialog.dialogVisible = true
        if(v.face_url.length > 0)
          this.preview_imgurl = methodlist.resident_pic_base + v.face_url +"?tempid="+Math.random()        

      },
      //表格分页   
      handlpages(v){
        this.configs.page_index = v
        this.getApproveVisitorList();
      },
      handleSizeChange(v){
        this.configs.page_index=1
        this.configs.pagesize = v
        this.getApproveVisitorList();
      },
      // 预览关闭弹窗
      dialogVisibletrue(){
         console.log("dialogVisibletrue");
        this.$refs.dialog.dialogVisible = false
      },
      //  x 关闭弹窗
      handleClose(){
        console.log("handleClose");
        this.$refs.dialog.dialogVisible = false
      },
      //删除弹窗: 确定删除访客
      userdialogVisibletrue(){
        console.log("userdialogVisibletrue");
        this.userdialog.dialogVisible=false;
        if(this.delform.user_name_input != this.UserInfo.user_name_save){
            this.$message({
              type: 'warning',
              message: this.$t('devtable.yhmsryw')
            })
            return false;
        }

        if(this.delform.user_pass_input != this.UserInfo.user_pass_save){
            this.$message({
              type: 'warning',
              message: this.$t('devtable.yhmmsryw')
            })
            return false;
        }
        const params = {
            method: methodlist.ee_visitor_del,
            name: this.delform.user_name_input,
            password: this.$md5(this.delform.user_pass_input),
            id: this.delData.id
        }
        this.$serve(params).then(res => {          
            console.log(res);
            if(res.data.code == 0){
              this.$message({
                message: this.$t('devtable.sccg'),
                type: 'success'
              })        
              this.getApproveVisitorList(1);
            }else{
              //删除失败
              if(res.data.code == -3){
                this.$message({
                  message: this.$t('devtable.scsbfkbcz'),
                  type: 'warning'
                }) 
            }else{
                this.$message({
                  message: this.$t('devtable.scsb'),
                  type: 'warning'
                }) 
            }
            }
        });
      },
      handlerow(v,name){
        console.log("handlerow",v,name);
        if(name == '删除'|| name=='Delete'){
          this.delData = v;
          this.userdialog.dialogVisible=true;
        }        
      },
      //关闭删除框
      userhandleClose(){
        console.log("userhandleClose");
        this.userdialog.dialogVisible=false
      },
      //注册访客成功调用
      regsiterSuccess(){
        console.log("from registration");
        this.getApproveVisitorList(1);
      },
      //查询显示列表
      searchListWithParams(data){
        console.log('search',data);
        this.searchParams = data;
        this.getApproveVisitorList(1);
      },
      //获取访客列表
      getApproveVisitorList(pageIndex){
        const params = {          
          method: methodlist.ee_visitor_approve_list,
          agent_id: this.UserInfo.agent_id,
          manager_id: this.UserInfo.manager_id, 
          page_index: pageIndex?pageIndex:this.configs.page_index, 
          page_size: this.configs.pagesize,          
          name: this.searchParams.name,
          mobile: this.searchParams.mobile,
          person_name: this.searchParams.person_name,
          person_mobile: this.searchParams.person_mobile,
          start_time: this.searchParams.start_time.length == 0 ? '' : this.searchParams.start_time/1000,
          end_time: this.searchParams.end_time.length == 0 ? '' : this.searchParams.end_time/1000,
          reg_face_status: this.searchParams.reg_face_status
          //agent_id:2,
        }

        this.$serve(params).then(res => {
          console.log(res);
          this.tableData = res.data.data;
          this.tableData.forEach(el=>{
              if(el.gender == 0){
                el.gender_hint={
                  name: this.$t('devtable.male'),
                  type: 'primary',
                  state: true
                }
              }else{
                el.gender_hint={
                  name: this.$t('devtable.female'),
                  type: 'primary',
                  state: true
                }
              }
              

              el.face_url_hint = {
                    name:this.$t('devtable.yl'),
                    but:true,                                        
                    type:'success'
              }
              console.log('312',el.visit_time)
              if(el.visit_time == 0 || el.visit_time.length == 0){
                  el.visit_time_hint = ''
              }else{
                  el.visit_time_hint = {
                    name: ''+formatDate(new Date(el.visit_time * 1000), 'yyyy-MM-dd hh:mm'),
                    type: 'primary',
                    state: true
                  }
              }
          })
          this.configs.total = res.data.count;
        })

      }
    }
}
</script>
<style lang="less" scoped>
.statusquery{
    background-color: #fff;
    padding: 20px;
    .searchfrom{
      padding: 20px;
      margin-top: 20px;
    }
    .dei_rowp{
      display: flex;
      width: 30%;
      .dev_dowm{
        width: 200px;
      }
      .but{
        margin-left: 20px;
        margin-right: 30px;
      }
    }
    .dialogimg{
      img{
        width: 80%;
      }
    }
}
.status_row{
    display: flex;
}
</style>