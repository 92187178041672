<template>
    <div>
        <el-dialog
        :title="$t('devtable.tsxx')"
        :visible.sync="centerDialogVisible"
        width="990px"
        append-to-body
        center>
            <div class="regin_cont">
                <div class="regin_bot">
                    <div class="regin_row_left">
                        <div class="regin_left_header">
                            <h3>
                                <span>1</span>
                                {{$t('devtable.zpdj')}}
                            </h3>
                            <el-row class="butregin">
                                
                               <el-col :span="12"> <el-button type="primary" size="mini" @click="setImage()">{{$t('devtable.pzz')}}</el-button></el-col>
                                <el-col :span="10" :offset="2">
                                    <el-button type="primary" class="uplouds" @click="choiceImg" size="mini">{{$t('devtable.zpxz')}}
                                        <input id="imagePic" name="imagePic" @change="getFile" ref="filElem" class="butuploud" type="file"
                                        accept="image/jpg,image/jpeg" />
                                    </el-button>
                                </el-col>
                                
                            </el-row>
                        </div>
                        <div class="regin-left-cont">
                            <div class="kuang">
                                
                                 <video v-show="!imgSrc" id="videoCamera" :width="videoWidth" :height="videoHeight" autoplay></video>
                                 <canvas style="display:none;" id="canvasCamera" :width="videoWidth" :height="videoHeight" ></canvas>
                                
                                <!-- <div v-show="imgSrc" class="img_bg_camera">
                                    <img  :src="imgSrc" alt class="tx_img" />
                                </div> -->
                                
                            </div>
                        </div>
                        <div class="sherengin">
                            <el-button type="primary" size="mini" @click="getCompetence()">{{$t('devtable.dksxt')}}</el-button>
                            <el-button type="primary" size="mini" @click="stopNavigator()">{{$t('devtable.gbsxt')}}</el-button>
                        </div>
                    </div>
                    <div class="regin_row_right">
                        <div class="regin_right_header">
                            <h3>
                                <span>2</span>
                                {{$t('devtable.lfxxdj')}}
                            </h3>
                        </div>
                        <div class="right_cont_row">
                            <el-form ref="form" :model="form" label-width="120px" size="mini">
                                <div class="right_cont_poy">
                                    <el-form-item :label="$t('devtable.lfr')">
                                        <el-input v-model="form.v_name"></el-input>
                                    </el-form-item>
                                    <el-form-item :label="$t('devtable.xb')">
                                        <el-select v-model="form.v_gender" :placeholder="$t('devtable.qxz')">
                                        <el-option selected :label="$t('devtable.male')" value="0"></el-option>
                                        <el-option :label="$t('devtable.female')" value="1"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                
                                <div class="fromig">
                                    <div class="fromct">
                                        <el-form-item :label="$t('devtable.zjlx')">
                                            <el-select v-model="form.v_credential_type" :placeholder="$t('devtable.qxz')">
                                            <el-option :label="$t('devtable.sfz')" value="0"></el-option>
                                            <el-option :label="$t('devtable.hz')" value="1"></el-option>
                                            <el-option :label="$t('devtable.jzz')" value="2"></el-option>
                                            <el-option :label="$t('devtable.jsz')" value="3"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item :label="$t('devtable.zjhmH')" label-width="130px">
                                            <el-input v-model="form.v_credential"></el-input>
                                        </el-form-item>
                                        <el-form-item :label="$t('devtable.dww')">
                                            <el-input v-model="form.v_unit_name"></el-input>
                                        </el-form-item>
                                        <el-form-item :label="$t('devtable.dhh')">
                                            <el-input v-model="form.v_mobile"></el-input>
                                        </el-form-item>
                                    </div>
                                    <div class="frimage" >
                                         <img :src="imgSrc" alt style="width:90%;margin-left:10px;max-height:175px" />
                                    </div>
                                </div>
                               
                                <div class="cename">
                                    <el-form-item :label="$t('devtable.cph')" class="one">
                                        <el-input v-model="form.v_car_number"></el-input>
                                        <!-- <el-select v-model="form.region" placeholder="请选择活动区域">
                                        <el-option label="区域一" value="shanghai"></el-option>
                                        <el-option label="区域二" value="beijing"></el-option>
                                        </el-select> -->
                                    </el-form-item>
                                    <!-- <el-form-item label="随行人员" class="two">
                                        <el-input v-model="form.v_members"></el-input>
                                    </el-form-item> -->
                                    <el-form-item :label="$t('devtable.sxry')" class="two">
                                        <el-select v-model="form.v_members" :placeholder="$t('devtable.qxz')">
                                        <el-option label="1" value="1"></el-option>
                                        <el-option label="2" value="2"></el-option>
                                        <el-option label="3" value="3"></el-option>
                                        <el-option label="4" value="4"></el-option>
                                        <el-option label="5" value="5"></el-option>
                                        <el-option label="6" value="6"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>

                                <div class="beifangwr">
                                    <el-form-item :label="$t('devtable.bfr')" label-width="136px">
                                        <el-input readonly v-model="form.v_person_name"></el-input>
                                    </el-form-item>
                                    <el-row class="anniu">
                                        <el-button size="mini" type="primary" @click="Selectemploy">{{$t('devtable.xzyg')}}</el-button>
                                    </el-row>
                                </div>
                                <el-form-item :label="$t('devtable.bfsy')">
                                    <el-input v-model="form.v_reason"></el-input>
                                </el-form-item>
                                <el-form-item :label="$t('devtable.kh')">
                                    <el-input v-model="form.v_card_no"></el-input>
                                </el-form-item>
                                <div class="timest" style="display: flex;">
                                    <div style="display: flex;">
                                        <span style="flex:1;text-align: right;line-height: 30px;">{{$t('devtable.kssj')}}：</span>
                                         <el-date-picker
                                         style="flex:2"
                                            size="mini"
                                            v-model="form.v_start_time"
                                            type="datetime"
                                            value-format="timestamp"
                                            :placeholder="$t('devtable.xzrq')">
                                        </el-date-picker>
                                    </div>
                                    <div style="display: flex;">
                                        <span style="flex:1;text-align: right;line-height: 30px;">{{$t('devtable.jssj')}}： </span>
                                         <el-date-picker
                                            style="flex:2"
                                            size="mini"
                                            v-model="form.v_end_time"
                                            type="datetime"
                                            value-format="timestamp"
                                            :placeholder="$t('devtable.xzrq')">
                                        </el-date-picker>
                                    </div>
                                </div>
                            </el-form>
                        </div>
                    </div>
                </div>
                <div class="regin_top">
                    <div class="rgin_top_header">
                        <h3 style="flex:2">
                        <span>3</span>
                        {{$t('devtable.sbsq')}}
                        </h3>
                    
                        <div style="display: flex;flex:4;margin-right:20px">
                            <el-input v-model="key_serial_number" style="flex:1" placeholder=""></el-input>
                            <el-row><el-button type="primary" style="flex:1;margin-left:20px" @click="searchKeyDeviceList">{{$t('devtable.cx')}}</el-button></el-row>
                        </div>
                        <el-row style="flex:4;text-align: right;margin-right:60px">
                            <el-button type="primary" @click="registerNewVisitor">{{$t('tablename.qd')}}</el-button>
                        </el-row>
                    </div>
                    <div class="regin_top_content">
                        <div class="cont_table">
                            <Tables @handleSelectionChange ="handleSelectionChange" @handlpages="handlpages" :tableLabel="tableLabel" :configs="configs" :flags="flags" :tableData="tableData"></Tables>
                        </div>
                        <!-- <div class="erma">
                            <h3>生成访客二维码</h3>
                            <div style="margin-top:20px"> -->
                                <!-- <el-row  style="margin-bottom:20px"> -->
                                    <!-- <el-button type="primary" @click="createTempPassCode">生成临时密码</el-button> -->
<!--                                    
                                </el-row>
                                <el-row>
                                     <el-button type="success">生成二维码</el-button>
                                </el-row>
                            </div> -->
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </el-dialog>
        <Vuecropperr ref="vuecrropperr" @finish="finish"></Vuecropperr>
        <Selectemploy @selPersonSuccess="selPersonSuccess" ref="Selectemploy"></Selectemploy>        
    </div>
</template>
<script>
import Vuecropperr from './vuecropperr'
import Selectemploy from './selectemploy'
import Tables from '@/components/table'
import methodlist from '../../../utils/methods'
import axios from 'axios'
import jq from 'jquery'
import qs from 'qs'
import {formatDate} from '../../../utils/date'
import { uploadPic } from '../../../api/uesr'

var Base64 = require('js-base64').Base64;
  export default {
      components:{
        Vuecropperr,
        Selectemploy,
        Tables
      },
    data() {
      return {
        user:'',
        centerDialogVisible: false,
        videoWidth: 200,
        videoHeight: 250,
        imgSrc: require('../../../assets/image/default_face_img.png'),
        thisCancas: null,
        thisContext: null,
        thisVideo: null,
        openVideo:false,
        dialogImageUrl: '',
        dialogVisible: false,
        form: {
            v_name: '',
            v_gender: '',
            v_credential_type: '',
            v_credential: '',
            v_unit_name: '',
            v_mobile: '',
            v_car_number: '',
            v_members: '',
            v_person_name: '',//被访问人显示名称 使用id
            v_person_id: '', // 被访问人id
            v_reason: '',
            v_card_no: '',
            v_start_time: '',
            v_end_time: '',          
            v_devices: ''
        },
        key_serial_number: '',//设备授权查询称称
         flags: {
                show: true, // 多选是否显示
                ishow: false // 序号是否显示
            },
            // 分页数据
            configs: {
                pagesize: 10,
                total: 200,
                loading: false // 加载状态
            },
         tableLabel: [
                {
                    prop: 'name',
                    label: this.$t('devtable.azdz'),
                    align: 'center'
                },
                {
                    prop: 'serial_number',
                    label: this.$t('devtable.sbxlh'),
                    align: 'center'
                },
                {
                    prop: 'start_time_hint',
                    label: this.$t('devtable.kssj'),
                    align: 'center'
                },
                {
                    prop: 'end_time_hint',
                    label: this.$t('devtable.jssj'),
                    align: 'center'
                },
                
            ],
            tableData: [
                // {
                //   name: '五金一厂生产部',
                //   xuliehao: 'F20A0653',
                //   status: '2020-11-20:15:35',
                //   kaimstatus: '2020-11-20:15:35',
                // }
            ],
            faceimg:{
                up_img_name_src: '',
                up_img_name_dst: '',
                person_id: '',
                up_img_flag: 0
            },
            UserInfo:''
      };
    },
    mounted () {
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
        this.form.v_gender='0';
        this.form.v_credential_type = '0';
        this.v_start_time=this.getNowTime(0)
            // v_end_time: '',  
        // this.searchKeyDeviceList();
    },
    methods:{
         getNowTime(value) {
	       var now = new Date();
	       var year = now.getFullYear()+value; //得到年份
	       var month = now.getMonth(); //得到月份
	       var date = now.getDate(); //得到日期
	       var hour =" 00:00:00"; //默认时分秒 如果传给后台的格式为年月日时分秒，就需要加这个，如若不需要，此行可忽略
	       month = month + 1;
	       month = month.toString().padStart(2, "0");
	       date = date.toString().padStart(2, "0");
	       var defaultDate = `${year}-${month}-${date}${hour}`;
	       console.log(defaultDate)
	       return defaultDate;
	       this.$set(this.info, "stockDate", defaultDate);
	    },
        startRegisterVisitor(){
            this.faceimg.up_img_flag = 0;
        },
        onSubmit() {
            console.log('submit!');
        },
        //分页
        handlpages(v){
            console.log(v)
        },
        //表格多选方法
        handleSelectionChange(val){
            console.log(val);
            this.form.v_devices = '';
            val.forEach(item =>{
                this.form.v_devices += 0 ? item.device_id : (','+item.device_id)
            })
            console.log(this.form.v_devices);
        },
        //注册新的访客
        registerNewVisitor(){
            if(this.form.v_name.length == 0){
                this.$message({
                    type: 'warning',
                    message: this.$t('devtable.lfrxmbnwk')
                })
                return false;                
            }
            if(this.form.v_person_id.length == 0){
                this.$message({
                    type: 'warning',
                    message:  this.$t('devtable.bfrbnwk')
                })
                return false;    
            }
            if(this.form.v_devices.length == 0){
                this.$message({
                    type: 'warning',
                    message:  this.$t('devtable.qxzsb')
                })
                return false;    
            }            
            const params = {
                method: methodlist.ee_visitor_add,
                agent_id: this.UserInfo.agent_id,
                name: this.form.v_name,
                gender: this.form.v_gender,
                credential_type: this.form.v_credential_type,
                credential:this.form.v_credential,
                unit_name: this.form.v_unit_name,
                mobile: this.form.v_mobile,
                car_number: this.form.v_car_number,
                members: this.form.v_members,
                reason: this.form.v_reason,
                card_no: this.form.v_card_no,
                start_time: this.form.v_start_time.length == 0 ? '' : this.form.v_start_time/1000,
                end_time: this.form.v_end_time.length == 0 ? '' : this.form.v_end_time/1000,
                person_id: this.form.v_person_id,
                devices: this.form.v_devices,
            }
            console.log(params);
            this.$serve(params).then(res => {
                console.log(res);
                if(res.data.code == 0){
                    this.$message({
                        type: 'success',
                        message: this.$t('devtable.fkzccg')
                    })
                    this.centerDialogVisible = false;
                    this.$emit("regsiterSuccess");
                    this.faceimg.person_id = res.data.data.id;
                    if(this.faceimg.up_img_flag == 1)
                        this.deletePersonOldFaceImg();
                }else{
                    this.$message({
                        type: 'warning',
                        message: this.$t('devtable.fkzcsb')
                    })
                }
            })
        },

        //访客住户设备列表(需要先选择住户员工)
        searchKeyDeviceList(){
            const params = {
                method: methodlist.ee_visitor_dev_list,
                serial_number: this.key_serial_number,
                person_id: this.form.v_person_id,
                manager_id: this.UserInfo.manager_id
            }

            this.$serve(params).then(res => {
                this.tableData = res.data.data;
                this.configs.total = res.data.count;
                this.tableData.forEach(item =>{
                    if(item.start_time == 0 || item.start_time.length == 0){
                        item.start_time_hint = ""
                    }else{
                        item.start_time_hint = {
                            name: ''+formatDate(new Date(item.start_time * 1000), 'yyyy-MM-dd hh:mm'),
                            type: 'primary',
                            state: true
                        }
                    }

                    if(item.end_time == 0 || item.end_time.length == 0){
                        item.end_time_hint = ""
                    }else{
                        item.end_time_hint = {
                            name: ''+formatDate(new Date(item.end_time * 1000), 'yyyy-MM-dd hh:mm'),
                            type: 'primary',
                            state: true
                        }
                    }
                })
            })
        },
        // 打开选择员工界面选择员工
        Selectemploy(){            
            this.$refs.Selectemploy.dialogFormVisiblet=true           
        },
        //选择被访员工返回数据
        selPersonSuccess(data){
            console.log(data)
            this.form.v_person_name = data.name;
            this.form.v_person_id = data.person_id;
            this.searchKeyDeviceList();
        },
        finish(e){
            console.log(e)
            console.log('上传base64图片');
            this.imgSrc=e
            this.uploadFaceImg(e)
            
        },
        //上传人脸图片
        uploadFaceImg(imgData){
            console.log(imgData.substring('data:image/jpeg;base64,'.length))
            
            let mqttInfo = this.UserInfo.mqtt_serial + ':' + this.UserInfo.mqtt_password;
            //mqttInfo = '0AwAA:81905683';//测试用
            let curTimestamp = new Date().getTime();
            this.faceimg.up_img_name_src = methodlist.type_face + curTimestamp + ".jpeg";
            let up_url_Path = methodlist.upload_img_base + this.faceimg.up_img_name_src;
            var data = 'temporary=true&imgBase64='+imgData.substring('data:image/jpeg;base64,'.length);
            var _this = this;
            console.log(`0900`,up_url_Path)
            jq.ajax({
				headers : {
					'Authorization' : 'Basic '+Base64.encode(mqttInfo),
					'Content-MD5': this.$md5(data)
				},
				url: up_url_Path,	 
				type : 'POST',
				contentType: "application/x-www-form-urlencoded",
				data : data,
				success : function(res) {	
                    console.log(res);                    
                    // setTimeout(() => {
                    //     _this.renamePersonImgName();
                    // }, 2000);
                    _this.faceimg.up_img_flag = 1;
				},
				error : function(res){
                    console.log(res);						
				}
			});
                
            // let data = {
            //     temporary:true,
            //     imgBase64:imgData.substring('data:image/jpeg;base64,'.length)                
            // }                
            // axios({
            //     headers : {
			// 		'Authorization' : 'Basic '+Base64.encode(mqttLoginInfo),
			// 		'Content-MD5':this.$md5(qs.stringify(data))
			// 	},
			// 	url: up_url_Path,
			// 	method : 'POST',
			// 	contentType: "application/x-www-form-urlencoded",
			// 	data : qs.stringify(data)
            // }).then((res)=>{
            //     console.log("上传图片结果N:")
            //     console.log(res)
            // });
        },
        //修改人脸图片的名称
        renamePersonImgName(){
			let id8HexUp = this.imgHexNameFromPersonId();
			this.faceimg.up_img_name_dst = methodlist.type_face + id8HexUp+".jpg";
			
			if(this.faceimg.up_img_name_src.length == 0 || this.faceimg.up_img_name_dst.length == 0){
				console.log("图片文件路径错误！");
				return false;
			}

            let mqttInfo = this.UserInfo.mqtt_serial + ':' + this.UserInfo.mqtt_password;
            
            //mqttInfo = '0AwAA:81905683';//测试用
                        
            var data = 'temporary=true&rename='+this.faceimg.up_img_name_dst;
            var _this = this;
			jq.ajax({
				headers : {
					'Authorization' : 'Basic '+Base64.encode(mqttInfo),
						'Content-MD5': this.$md5(data),
					},
					url : methodlist.upload_img_base + this.faceimg.up_img_name_src, 
					type : 'POST',
					contentType: "application/x-www-form-urlencoded",
					data : data,
					success : function(res) {						
                        console.log('改名成功:',res)
                        _this.freshServerImgName(res);
					},
					error : function(res){
                        console.log('改名失败:',res)
					}
				});
        },
        
        //删除没用的人脸图片
        deletePersonOldFaceImg(){
				
            let mqttInfo = this.UserInfo.mqtt_serial + ':' + this.UserInfo.mqtt_password;            
            //mqttInfo = '0AwAA:81905683';//测试用                
			var id8HexUp = this.imgHexNameFromPersonId();
			var delImgPath=methodlist.upload_img_base + methodlist.type_face + id8HexUp+".jpg";
            var data = '';
            var _this = this;
			jq.ajax({
				headers : {
				    'Authorization' : 'Basic '+Base64.encode(mqttInfo),
					'Content-MD5': data,
				},
				url : delImgPath, 
				type : 'DELETE',							
				contentType: "false",
				data : data,
				success : function(res) {
					console.log("删除人脸图片OK",JSON.stringify(res));
					_this.deletePersonOldFaceTemp();
				},
				error : function(res){
					console.log("删除人脸图片Err",JSON.stringify(res));
					_this.deletePersonOldFaceTemp();
				}
			});
        },
        //删除没用的人脸模板
		deletePersonOldFaceTemp(){
				
            let mqttInfo = this.UserInfo.mqtt_serial + ':' + this.UserInfo.mqtt_password;            
            //mqttInfo = '0AwAA:81905683';//测试用
				
			var id8HexUp = this.imgHexNameFromPersonId();
			var delFaceTempUrl=methodlist.upload_img_base + methodlist.type_temp + id8HexUp+".bin";
            var data = '';
            var _this = this;
			jq.ajax({
				headers : {
					'Authorization' : 'Basic '+Base64.encode(mqttInfo),
					'Content-MD5': data,
				},
				url : delFaceTempUrl, 
				type : 'DELETE',
				contentType: "false",
				data : data,
				success : function(res) {
					console.log("删除人脸模板OK",JSON.stringify(res));
					setTimeout( ()=>{
					    _this.renamePersonImgName();
					}, 2 * 1000 );
				},
				error : function(res){
					console.log("删除人脸模板Err",JSON.stringify(res));
					setTimeout( ()=>{
						_this.renamePersonImgName();
					}, 2 * 1000 );
				}
			});
		},
        imgHexNameFromPersonId(){
            //this.faceimg.person_id = 1000;		
			let num = parseInt(this.faceimg.person_id).toString(16);
			let id8Hex = ('00000000'+num).slice(-8);
			let id8HexUpName = id8Hex.toUpperCase();
			return id8HexUpName;
        },
        //更新服务器
        freshServerImgName(img_url){
            const params = {
                method: methodlist.ee_update_person_pic,
                id: this.faceimg.person_id,
                pic_url:img_url
            }
            this.$serve(params).then(res => {          
                console.log(res);
                if(res.data.code == 0){
                    //添加访客成功完成
                }    
            });    
		},
        createTempPassCode(){
            this.deletePersonOldFaceImg();
        },
        choiceImg(){
            this.$refs.filElem.dispatchEvent(new MouseEvent('click')) 
        },
        getFile(e){
            var file = e.target.files[0];
            console.log(`9990000000`,e)
            var filesize = file.size;
            var filename = file.name;
            // 2,621,440   2M
            if (filesize > 2101440) {
                // 图片大于2MB
                console.log(11)
            }
            var reader = new FileReader();
            var _this=this
            reader.readAsDataURL(file);
            reader.onload = function (e) {
                console.log(`1111111`,e)
                // 读取到的图片base64 数据编码 将此编码字符串传给后台即可
                // var imgcode = e.target.result;
                // _this.imgSrc = e.target.result
                _this.$refs.vuecrropperr.dialogVisible=true
                _this.$refs.vuecrropperr.option.img=e.target.result
                // console.log(e.target.result)
                _this.imgSrc=e
                _this.uploadFaceImg(e)
            }
        },
        getCompetence() {
            var _this = this;
            _this.thisCancas = document.getElementById("canvasCamera");
            _this.thisContext = _this.thisCancas.getContext("2d");
            _this.thisVideo = document.getElementById("videoCamera");
            _this.thisVideo.style.display = 'block';
            // 获取媒体属性，旧版本浏览器可能不支持mediaDevices，我们首先设置一个空对象
            if (navigator.mediaDevices === undefined) {
                navigator.mediaDevices = {};
            }
            // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
            // 使用getUserMedia，因为它会覆盖现有的属性。
            // 这里，如果缺少getUserMedia属性，就添加它。
            if (navigator.mediaDevices.getUserMedia === undefined) {
                navigator.mediaDevices.getUserMedia = function(constraints) {
                // 首先获取现存的getUserMedia(如果存在)
                var getUserMedia =
                    navigator.webkitGetUserMedia ||
                    navigator.mozGetUserMedia ||
                    navigator.getUserMedia;
                // 有些浏览器不支持，会返回错误信息
                // 保持接口一致
                console.log(`567`,getUserMedia)
                if (!getUserMedia) {//不存在则报错
                    return Promise.reject(
                    new Error("getUserMedia is not implemented in this browser")
                    );
                }
                // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
                return new Promise(function(resolve, reject) {
                    getUserMedia.call(navigator, constraints, resolve, reject);
                });
                };
            }
            var constraints = {
                audio: false,
                video: {
                width: this.videoWidth,
                height: this.videoHeight,
                transform: "scaleX(-1)"
                }
            };
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then(function(stream) {
                // 旧的浏览器可能没有srcObject
                if ("srcObject" in _this.thisVideo) {
                    _this.thisVideo.srcObject = stream;
                } else {
                    // 避免在新的浏览器中使用它，因为它正在被弃用。
                    _this.thisVideo.src = window.URL.createObjectURL(stream);
                }
                _this.thisVideo.onloadedmetadata = function(e) {
                    _this.thisVideo.play();
                };
                })
                .catch(err => {
                console.log(err);
                });
        },
         //  绘制图片（拍照功能）
        setImage() {
        var _this = this;
        // canvas画图
        _this.thisContext.drawImage(
            _this.thisVideo,
            0,
            0,
            _this.videoWidth,
            _this.videoHeight
        );
        // 获取图片base64链接
        var image = this.thisCancas.toDataURL("image/jpeg");
        _this.finish(image)
        _this.imgSrc = image;//赋值并预览图片
        },
        // 关闭摄像头
        stopNavigator() {
            this.thisVideo.srcObject.getTracks()[0].stop();
        }
    }
  };
</script>
<style lang="less" scoped>
.regin_cont{
    height:900px;
    .regin_bot{
        display: flex;
        .regin_row_left{
            flex: 3;
            border: 1px solid #ccc;
            height: 530px;
            padding: 5px;
            .regin_left_header{
                display: flex;
                height: 50px;
                border: 1px solid #ccc;
                line-height: 50px;
                // background-color: red;
                h3{
                    font-size: 16px;
                    font-weight: 600;
                    span{
                        display: inline-block;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        border-radius: 50%;
                        background-color: #409EFF;
                        text-align: center;
                        color: #fff;
                        margin-right: 10px;
                        margin-left: 10px;
                    }
                }
                .butregin{
                    margin-left: 20px;
                    .uplouds{
                        position: relative;
                        .butuploud{
                            margin: 0;
                            padding: 0;
                            width:100%;
                            height:100%;
                            z-index:999999;
                            position: absolute;
                            left: 0;
                            top: 0;
                            display: none;
                        }
                    }
                    
                }
            }
            .regin-left-cont{
                display: flex;
                flex-direction: column;
                align-items:center;
                justify-content: center;
                .kuang{
                    width: 200px;
                    height:250px;
                    border: 1px solid #67C23A;
                    margin-top: 60px;
                    .img_bg_camera{
                        
                        .tx_img{
                            width: 200px;
                            height: 250px;
                        }
                    }
                }
            }
            .sherengin{
                height: 40px;
                padding: 2px;
                margin-top: 120px;
                border: 1px solid #ccc;
                line-height: 40px;
                text-align: center;
                cursor: pointer;
            }
        }
        .regin_row_right{
            flex: 4;
            border: 1px solid #ccc;
            height: 530px;
            padding: 5px;
            margin-left: 20px;
            .regin_right_header{
                display: flex;
                height: 50px;
                border: 1px solid #ccc;
                line-height: 50px;
                
                h3{
                    font-size: 16px;
                    font-weight: 600;
                    text-align: center;
                    margin: 0 auto;
                    span{
                        display: inline-block;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        border-radius: 50%;
                        background-color: #409EFF;
                        text-align: center;
                        color: #fff;
                        margin-right: 20px;
                    }
                }
            }
            .right_cont_row{
                margin-top: 20px;
                .right_cont_poy{
                    display: flex;
                }
                .fromig{
                    display: flex;
                    .fromct{
                        flex: 2;
                    }
                    .frimage{
                        flex: 1;
                    }
                }
                .cename{
                    display: flex;
                    .one{
                        flex: 3;
                    }
                    .two{
                        flex: 2;
                        margin-right: 10px;
                    }
                    .three{
                        flex: 1;
                        .el-form-item__content{
                            
                        }
                    }
                }
                .beifangwr{
                    display: flex;
                    .anniu{
                        margin-left: 10px;
                    }
                }
                .timest{
                    // display: flex;
                }
            }
        }
    }
    .regin_top{
        width: 100%;
        height: 250px;
        border: 1px solid #ccc;
        margin-top: 10px;
        .rgin_top_header{
            display: flex;
            height: 50px;
            border: 1px solid #ccc;
            line-height: 50px;
            h3{
                font-size: 16px;
                font-weight: 600;
                text-align: center;
                margin-left: 20px;
                margin-right: 10px;
                span{
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    border-radius: 50%;
                    background-color: #409EFF;
                    text-align: center;
                    color: #fff;
                    margin-right: 20px;
                }
            }
        }
        .regin_top_content{
            display: flex;
            .cont_table{
                flex: 3;
            }
            .erma{
                flex: 1;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
}
.three /deep/ .el-form-item__content{
    margin-left: 0px !important;;
}
</style>