<template>
    <div class="slecply">
        <el-dialog :title="$t('devtable.xzyg')" width="30%" append-to-body :visible.sync="dialogFormVisiblet">
            <el-form :model="form">
                <!-- <el-form-item label="企业部门" size="mini" :label-width="formLabelWidth">
                    <Selectree></Selectree>
                </el-form-item> -->
                <el-form-item :label="$t('devtable.xm')" :label-width="formLabelWidth">
                    <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('devtable.sjhm')" :label-width="formLabelWidth">
                    <el-input v-model="form.mobile" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="selecteResPerson">{{$t('tablename.qd')}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Selectree from '@/components/selectree'
import methodlist from '../../../utils/methods'

export default {
    components:{
        Selectree
    },
    data(){
        return{
            dialogFormVisiblet: false,
            form: {
                name: '',
                mobile: ''
            },
            formLabelWidth: '110px',
            UserInfo:''
        }
    },
    mounted () {
      this.UserInfo = JSON.parse(window.localStorage.getItem('user'));  
    },
    methods: {
        selecteResPerson(){
            const params = {
                method: methodlist.ee_visitor_person_sel,
                name: this.form.name,
                mobile: this.form.mobile,
                agent_id: this.UserInfo.agent_id
                //agent_id: 2
            }

            this.$serve(params).then(res => {
                console.log(res);
                if(res.data.code == 0){                    
                    this.dialogFormVisiblet = false;
                    console.log('54',res.data.data);
                    this.$emit("selPersonSuccess",res.data.data);
                }else {
                    if(res.data.code == -3){
                        this.$message({
                            type: 'warning',
                            message: this.$t('devtable.rybcz')
                        })
                    }
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>

</style>