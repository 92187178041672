<template>
    <div>
        <el-form size="small" :inline="true" :model="formParams" class="demo-form-inline">
        <el-form-item :label="$t('devtable.lfr')">
            <el-input v-model="formParams.name" :placeholder="$t('devtable.lfr')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('devtable.sjhm')">
            <el-input v-model="formParams.mobile" :placeholder="$t('devtable.sjhm')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('devtable.sfr')">
            <el-input v-model="formParams.person_name" :placeholder="$t('devtable.sfr')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('devtable.sfrsjhm')">
            <el-input v-model="formParams.person_mobile" :placeholder="$t('devtable.sfrsjhm')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('devtable.kssjS')">
            <el-date-picker
            v-model="formParams.start_time"
            value-format="timestamp"
            type="datetime"
            :placeholder="$t('devtable.xzrqsj')">
            </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('devtable.jssjS')">
            <el-date-picker
            v-model="formParams.end_time"
            value-format="timestamp"
            type="datetime"
            :placeholder="$t('devtable.xzrqsj')">
            </el-date-picker>
        </el-form-item>

        <el-form-item>
            <el-button type="primary" @click="onSubmit(formParams)">{{$t('devtable.cx')}}</el-button>
        </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    data() {
      return {
        formParams: {
          name: '',
          mobile: '',
          person_name: '',
          person_mobile: '',
          start_time: '',
          end_time: ''
        }
      }
    },
    methods: {
      onSubmit() {
        console.log('submit!');
        this.$emit('searchListWithParams',this.formParams);
      }
    }
}
</script>
<style lang="less" scoped>

</style>